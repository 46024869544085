import React from 'react'
import useFormattedDate from '../Context/useFormattedDate'

const RegionAnnouncementCard = ({ announcement }) => {
    const slug = announcement.duyuru_link
        ?.split("/")
        .filter(Boolean)
        .pop()
        ?.replace("?", "");
    return (
        <div className="col-md-6">
            <div className="news-post standard-post2">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="post-gallery">
                    <img style={{ maxHeight: 200, maxWidth: 200 }} src={announcement?.image} alt="" />
                </div>
                <div className="post-title">
                    <h2>
                        <a href={announcement.duyuru_content !== "" ? `/announcement/${slug}-${announcement.id}` : announcement.duyuru_link}>
                            {announcement?.duyuru_title.slice(0, 38) + "..."}
                        </a>
                    </h2>
                    <ul className="post-tags">
                        <li>
                            <i className="fa fa-clock-o" />
                            {useFormattedDate(announcement?.duyuru_date)}
                        </li>
                        <li>
                            <i className="fa fa-map-marker" />
                            <a href="/">{announcement.abbreviation ? announcement?.country + "-" + announcement?.abbreviation : announcement?.name}</a>
                        </li>
                    </ul>
                </div>
                <div className="post-content">
                    <p>
                        {announcement.duyuru_content.slice(0, 220) + "..."}
                    </p>
                    <a href={announcement.duyuru_content !== "" ? `/announcement/${slug}-${announcement.id}` : announcement.duyuru_link} className="read-more-button">
                        <i className="fa fa-arrow-circle-right" />
                        Read More
                    </a>
                </div>
            </div>
        </div>
    )
}

export default RegionAnnouncementCard