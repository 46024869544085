import React from 'react'
import { useState, useEffect } from "react";
import NavbarCard from '../../Layout/NavbarCard';
import axios from 'axios';
const Navbar = () => {


  const [currentDate, setCurrentDate] = useState("");
  const [announcement, setAnnouncement] = useState([]);
  const [menaAnnouncements, setMenaAnnouncements] = useState([]);
  const [europeAnnouncements, setEuropeAnnouncements] = useState([]);
  const [asiaPacificAnnouncements, setAsiaPacificAnnouncements] = useState([]);
  const [americasAnnouncements, setAmericasAnnouncements] = useState([]);
  const [africaAnnouncements, setAfricaAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const response = await axios.get("https://medrais.com/api/announcements/top5region");

        setAnnouncement(response.data); // Tüm veriyi kaydet
        setLoading(false);

        // Veriyi region'a göre filtreleyip ilgili state'lere at
        setMenaAnnouncements(response.data.filter(item => item.region === "MENA"));
        setEuropeAnnouncements(response.data.filter(item => item.region === "Europe"));
        setAsiaPacificAnnouncements(response.data.filter(item => item.region === "Asia-Pacific"));
        setAmericasAnnouncements(response.data.filter(item => item.region === "Americas"));
        setAfricaAnnouncements(response.data.filter(item => item.region === "Africa"));
        console.log(menaAnnouncements)
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchAdsData();
  }, []);

  useEffect(() => {
    const updateDate = () => {
      const date = new Date();
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      setCurrentDate(formattedDate);
    };

    updateDate(); // İlk render'da tarihi ayarla
  }, []);
  return (
    <header className="clearfix second-style">
      {/* Bootstrap navbar */}
      <nav className="navbar navbar-default navbar-static-top" role="navigation">
        {/* Top line */}
        <div className="top-line">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <ul className="top-line-list">
                  <li>
                    <span className="time-now">
                      {currentDate}
                    </span>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <ul className="social-icons">
                  <li>
                    <a className="google" href="/">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                  <li>
                    <a className="linkedin" href="https://www.linkedin.com/company/biomedista/" target='_blank'>
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* End Top line */}
        {/* Logo & advertisement */}
        <div className="logo-advertisement">
          <div className="container">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href="/">
                <img style={{ marginTop: window.innerWidth > 768 ? -25 : -20, marginBottom: window.innerWidth > 768 ? -40 : -20 }} src="https://medrais.com/medrais_images/newmedraislogo.png" alt="" />
              </a>
            </div>
            <div className="advertisement">
              <div className="desktop-advert">
                <span>Advertisement</span>
                <a href='https://www.biomedista.com' target='_blank'>
                  <img style={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    height: "90px",
                    marginLeft: "-10px",
                    marginRight: "20px",
                    background: "#fff"
                  }} src="https://medrais.com/medrais_images/biomedista1.jpeg" alt="" />
                </a>

              </div>

              <div className="tablet-advert">
                <span>Advertisement</span>
                <img src="/upload/addsense/468x60-white.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* End Logo & advertisement */}
        {/* navbar list container */}
        <div className="nav-list-container">
          <div className="container">
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-left">
                <li className="no-arrow">
                  <a className="home" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="world" href="/region-announcement/Africa">
                    Africa
                  </a>
                  <div className="megadropdown">
                    <div className="container">
                      <div className="inner-megadropdown world-dropdown">
                        <div className="filter-block">
                          <ul className="filter-posts">
                            <li>
                              <a href="/subregion-announcement/Northern Africa">Northern Africa</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Southern Africa">Southern Africa</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Eastern Africa">Eastern Africa</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Western Africa">Western Africa</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Continental Bodies">Continental Bodies</a>
                            </li>
                            <li>
                              <a href="/region-announcement/Africa">All</a>
                            </li>
                          </ul>
                        </div>
                        <div className="posts-filtered-block">
                          <div className="owl-wrapper">
                            <h1>Latest announcements for Africa</h1>
                            <div className="owl-carousel" data-num={africaAnnouncements.length - 1}>
                              {africaAnnouncements.map((announcement, index) => (
                                <NavbarCard announcement={announcement} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a className="tech" href="/region-announcement/Americas">
                    Americas
                  </a>
                  <div className="megadropdown">
                    <div className="container">
                      <div className="inner-megadropdown tech-dropdown">
                        <div className="filter-block">
                          <ul className="filter-posts">
                            <li>
                              <a href="/subregion-announcement/Caribbean">Caribbean</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Central America">Central America</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Southern America">Southern America</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Northern America">Northern America</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Continental Bodies">Continental Bodies</a>
                            </li>
                            <li>
                              <a href="/region-announcement/Americas">All</a>
                            </li>
                          </ul>
                        </div>
                        <div className="posts-filtered-block">
                          <div className="owl-wrapper">
                            <h1>Latest announcements for Americas</h1>
                            <div className="owl-carousel" data-num={americasAnnouncements.length - 1}>
                              {americasAnnouncements.map((announcement, index) => (
                                <NavbarCard announcement={announcement} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a className="fashion" href="/region-announcement/Asia-Pacific">
                    Asia-Pacific
                  </a>
                  <div className="megadropdown">
                    <div className="container">
                      <div className="inner-megadropdown fashion-dropdown">
                        <div className="filter-block">
                          <ul className="filter-posts">
                            <li>
                              <a href="/subregion-announcement/CIS">CIS</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Eastern Asia">Eastern Asia</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Southern Asia">Southern Asia</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Oceania">Oceania</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Continental Bodies">Continental Bodies</a>
                            </li>
                            <li>
                              <a href="/region-announcement/Asia-Pacific">All</a>
                            </li>
                          </ul>
                        </div>
                        <div className="posts-filtered-block">
                          <div className="owl-wrapper">
                            <h1>Latest announcements for Asia-Pacific</h1>
                            <div className="owl-carousel" data-num={asiaPacificAnnouncements.length - 1}>
                              {asiaPacificAnnouncements.map((announcement, index) => (
                                <NavbarCard announcement={announcement} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a className="video" href="/region-announcement/Europe">
                    Europe
                  </a>
                  <div className="megadropdown">
                    <div className="container">
                      <div className="inner-megadropdown video-dropdown">
                        <div className="filter-block">
                          <ul className="filter-posts">
                            <li>
                              <a href="/subregion-announcement/Mediterranean Europe">Mediterranean Europe</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Eastern Europe">Eastern Europe</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Northern Europe">Northern Europe</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Western Europe">Western Europe</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Continental Bodies">Continental Bodies</a>
                            </li>
                            <li>
                              <a href="/region-announcement/Europe">All</a>
                            </li>
                          </ul>
                        </div>
                        <div className="posts-filtered-block">
                          <div className="owl-wrapper">
                            <h1>Latest announcements for Europe</h1>
                            <div className="owl-carousel" data-num={europeAnnouncements.length - 1}>
                              {europeAnnouncements.map((announcement, index) => (
                                <NavbarCard announcement={announcement} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a className="travel" href="/region-announcement/MENA">
                    MENA
                  </a>
                  <div className="megadropdown">
                    <div className="container">
                      <div className="inner-megadropdown travel-dropdown">
                        <div className="filter-block">
                          <ul className="filter-posts">
                            <li>
                              <a href="/subregion-announcement/Northern Africa">Northern Africa</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/GCC">GCC</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Levant">Levant</a>
                            </li>
                            <li>
                              <a href="/subregion-announcement/Continental Bodies">Continental Bodies</a>
                            </li>
                            <li>
                              <a href="/region-announcement/MENA">All</a>
                            </li>
                          </ul>
                        </div>
                        <div className="posts-filtered-block">
                          <div className="owl-wrapper">
                            <h1>Latest announcements for MENA</h1>
                            <div className="owl-carousel" data-num={menaAnnouncements.length - 1}>
                              {menaAnnouncements.map((announcement, index) => (
                                <NavbarCard announcement={announcement} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="no-arrow">
                  <a className="features" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
              <form className="navbar-form navbar-right" role="search">
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search here"
                />
                <button type="submit" id="search-submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
            {/* /.navbar-collapse */}
          </div>
        </div>
        {/* End navbar list container */}
      </nav>
      {/* End Bootstrap navbar */}
    </header>

  )
}

export default Navbar