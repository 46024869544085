import React, { useEffect, useState } from 'react'
import Sidebar from '../../Layout/Sidebar'
import RegionAnnouncementCard from '../../Layout/RegionAnnouncementCard'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SubRegionAnnouncement = () => {
    const [announcements, setAnnouncementss] = useState([]);
    const { subregion } = useParams(); // :slug-:id parametresi
    console.log(subregion)
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchAllRecords = async () => {
            try {
                const res = await axios.get(`https://medrais.com/api/announcements/sub-region/${subregion}`);
                setAnnouncementss(res.data);
                console.log(res.data)

            } catch (error) {
                console.log(error);
            }
        };

        fetchAllRecords();
    }, [subregion]);

    const sortedAnnouncements = [...announcements].sort((a, b) => b.id - a.id);

    // Sayfa başına gösterilecek duyuru sayısı
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);

    // Sayfa değiştirildiğinde gösterilecek duyurular
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedAnnouncements = sortedAnnouncements.slice(
        startIndex,
        startIndex + itemsPerPage
    );

    // Toplam sayfa sayısı
    const totalPages = Math.ceil(sortedAnnouncements.length / itemsPerPage);

    // Sayfa değiştirme fonksiyonu
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Pagination sayfa numaralarını hesapla
    const pageNumbers = () => {
        const pages = [];

        // İlk sayfa numarasını ekle
        if (currentPage > 3) pages.push(1);

        // Önceki 2 sayfa
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            if (i > 0 && i <= totalPages) {
                pages.push(i);
            }
        }
        pages.push("...")
        // Son sayfa numarasını ekle
        if (currentPage < totalPages - 2) pages.push(totalPages);

        return pages;
    };
    const getRegionColor = (region) => {
        switch (region) {
          case "Africa":
            return "#33a3f1";
          case "Americas":
            return "#74b430";
          case "Asia-Pacific":
            return "#aa35d6";
          case "Europe":
            return "#d6ac1b";
          case "MENA":
            return "#f58021";
          default:
            return "#cccccc"; // Eğer eşleşme yoksa varsayılan bir renk (gri)
        }
      };

    return (
        <>
            <section className="block-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8">
                            {/* block content */}
                            <div className="block-content">
                                {/* grid box */}
                                <div className="grid-box">
                                    <div className="title-section">
                                        <h1>
                                            <span style={{borderBottom:`1px solid ${ getRegionColor(subregion)}` }} className="world">{subregion}</span>
                                        </h1>
                                    </div>
                                    <div className="row">
                                        {selectedAnnouncements.map((announcement) => (
                                            <RegionAnnouncementCard key={announcement.id} announcement={announcement} />
                                        ))}
                                    </div>
                                </div>
                                {/* End grid box */}
                                {/* pagination box */}
                                <div className="pagination-box">
                                    <ul className="pagination-list" style={{ display: "flex", listStyle: "none" }}>
                                        {/* Previous Button */}
                                        <li>
                                            <button
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                style={{
                                                    backgroundColor: currentPage === 1 ? "#f0f0f0" : "#222222",
                                                    color: "#fff",
                                                    border: "1px solid #ddd",
                                                    padding: "5px 10px",
                                                    cursor: currentPage === 1 ? "not-allowed" : "pointer",
                                                }}
                                            >
                                                Prev
                                            </button>
                                        </li>

                                        {/* Sayfa numaralarını göster */}
                                        {pageNumbers().map((page, index) => (
                                            <li key={index}>
                                                <button
                                                    className={currentPage === page ? "active" : ""}
                                                    onClick={() => handlePageChange(page)}
                                                    style={{
                                                        backgroundColor: currentPage === page ? "#0056b3" : "#f0f0f0",
                                                        color: currentPage === page ? "#fff" : "#000",
                                                        border: "1px solid #ddd",
                                                        padding: "5px 10px",
                                                        cursor: "pointer",
                                                        margin: "0 5px",
                                                    }}
                                                >
                                                    {page}
                                                </button>
                                            </li>
                                        ))}

                                        {/* Next Button */}
                                        <li>
                                            <button
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                                style={{
                                                    backgroundColor: currentPage === totalPages ? "#f0f0f0" : "#222222",
                                                    color: "#fff",
                                                    border: "1px solid #ddd",
                                                    padding: "5px 10px",
                                                    cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                                                }}
                                            >
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                    <p>Page {currentPage} of {totalPages}</p>
                                </div>
                                {/* End Pagination box */}
                            </div>
                            {/* End block content */}
                        </div>
                        <Sidebar />
                    </div>

                </div>

            </section>
        </>


    )
}

export default SubRegionAnnouncement