import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import SingleAnnouncement from "./Components/Body/SingleAnnouncement";
import { useEffect } from "react";
import RegionAnnouncement from "./Components/Body/RegionAnnouncement";
import SubRegionAnnouncement from "./Components/Body/SubRegionAnnouncement";
import Contact from "./Components/Body/Contact";

function App() {
  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement("script");
      script.src = `${src}?v=${new Date().getTime()}`; // Burada sorgu parametresi ekliyoruz
      script.onload = callback;
      script.onerror = (error) => {
        console.log(`Error loading script at ${src}:`, error);
      };
      document.body.appendChild(script);
    };

    // Scriptleri sırayla yüklemek için zincirleme callback yapısı kullanıyoruz
    loadScript("/js/jquery.min.js", () => {
      loadScript("/js/jquery.migrate.js", () => {
        loadScript("/js/jquery.bxslider.min.js", () => {
          loadScript("/js/jquery.magnific-popup.min.js", () => {
            loadScript("/js/bootstrap.min.js", () => {
              loadScript("/js/jquery.ticker.js", () => {
                loadScript("/js/jquery.imagesloaded.min.js", () => {
                  loadScript("/js/jquery.isotope.min.js", () => {
                    loadScript("/js/owl.carousel.min.js", () => {
                        loadScript("/js/script.js"); // Son script yüklendikten sonra sonlandır
                      });
                    });
                  });
                });
              });
            });
          });
        });
    });

    return () => {
      // Cleanup function to remove scripts when component is unmounted
      const scripts = document.querySelectorAll('script[src^="/js"]');
      scripts.forEach((script) => script.remove());
    };
  }, []);  // Boş array ile sadece component mount olduğunda çalışır

  return (
    <Routes>
      <Route path="/admin/*">
      </Route>
      <Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/announcement/:id" element={<SingleAnnouncement />} />
        <Route path="/region-announcement/:region" element={<RegionAnnouncement />} />
        <Route path="/subregion-announcement/:subregion" element={<SubRegionAnnouncement />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
  );
}

export default App;
