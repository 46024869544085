import React, { useEffect, useState } from 'react';
import MainTop from '../../Layout/MainTop';
import MainAnnouncements from '../../Layout/MainAnnouncements';
import axios from 'axios';

const MainDuyuru = () => {
  const [announcement, setAnnouncement] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading state ekliyoruz

  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const response = await axios.get("https://medrais.com/api/announcements/getAll");
        const sortedData = response.data.announcements.sort((a, b) => {
          if (b.id !== a.id) {
            return b.id - a.id;
          }
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setAnnouncement(sortedData);
        setLoading(false);  // Veri geldikten sonra loading state'ini false yapıyoruz
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);  // Hata durumunda da loading false olur
      }
    };

    fetchAdsData();
  }, []);

  const topAnnouncements = announcement.slice(0, 7); // İlk 7 duyuruyu al
  const remainingAnnouncements = announcement.slice(7); // Kalanları al

  if (loading) {
    return <div>Loading...</div>; // Veri yüklenene kadar burada loading gösterebilirsiniz
  }

  return (
    <>
      <MainTop announcements={topAnnouncements} />
      <MainAnnouncements announcements={remainingAnnouncements} />
    </>
  );
};

export default MainDuyuru;
