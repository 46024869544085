import React from 'react'
import useFormattedDate from '../Context/useFormattedDate';

const MainTopBigCard = ({ announcement }) => {
    const getSlugFromUrl = (url) => {
        if (!url) return "";

        return url
            .split("/")
            .filter(Boolean)
            .pop()
            .replace("?", "");
    };
    const getRegionColor = (region) => {
        switch (region) {
          case "Africa":
            return "#33a3f1";
          case "Americas":
            return "#74b430";
          case "Asia-Pacific":
            return "#aa35d6";
          case "Europe":
            return "#d6ac1b";
          case "MENA":
            return "#f58021";
          default:
            return "#cccccc"; 
        }
      };

    return (

        <div className="news-post image-post">
            <img src={announcement.image} style={{ maxHeight: 480, maxWidth: 580 }} alt="" />
            <div className="hover-box">
                <div className="inner-hover">
                    <a className="category-post sport" style={{ backgroundColor:  getRegionColor(announcement?.region) }} href="sport.html">
                        {announcement?.region}
                    </a>
                    <h2>
                        <a
                            href={announcement?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcement?.duyuru_link)}-${announcement?.id}` : announcement?.duyuru_link}
                            style={{
                                display: "block",
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {announcement?.duyuru_title}{" "}
                        </a>
                    </h2>
                    <ul className="post-tags">
                        <li>
                            <i className="fa fa-clock-o" />
                            {useFormattedDate(announcement?.duyuru_date)}
                        </li>
                        <li>
                            <i className="fa fa-map-marker" />
                            <a href="/">{announcement.abbreviation ? announcement?.country + "-"+ announcement?.abbreviation : announcement?.name }</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default MainTopBigCard