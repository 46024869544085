import React from 'react'
import useFormattedDate from '../Context/useFormattedDate';

const AnnouncementCard = ({ data }) => {

    const slug = data.duyuru_link
        ?.split("/")
        .filter(Boolean)
        .pop()
        ?.replace("?", "");
    return (
        <div className="news-post article-post">
            <div className="row">
                <div className="col-sm-5">
                    <div className="post-gallery" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ maxHeight: 200, maxWidth: 200 }} alt="" src={data.image} />
                    </div>

                </div>
                <div className="col-sm-7">
                    <div className="post-content">
                        <h2>
                            <a href={data.duyuru_content !== "" ? `/announcement/${slug}-${data.id}` : data.duyuru_link}>
                                {data.duyuru_title?.length > 150
                                    ? `${data.duyuru_title.slice(0, 100)}...`
                                    : data.duyuru_title}

                            </a>
                        </h2>
                        <ul className="post-tags">
                            <li>
                                <i className="fa fa-clock-o" />
                                {useFormattedDate(data.duyuru_date)}
                            </li>
                            <li>
                                <i className="fa fa-map-marker" />
                                <a href="/">{data.abbreviation ? data?.country + "-" + data?.abbreviation : data?.name}</a>
                            </li>
                        </ul>
                        <p>
                            {data.duyuru_content?.length > 250
                                ? `${data.duyuru_content.slice(0, 150)}...`
                                : data.duyuru_content}
                        </p>
                        <a href={data.duyuru_content !== "" ? `/announcement/${slug}-${data.id}` : data.duyuru_link} className="read-more-button">
                            <i className="fa fa-arrow-circle-right" />
                            Read More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementCard