import React, { useEffect, useState } from 'react'
import Sidebar from '../../Layout/Sidebar'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SingleAnnouncement = () => {

  const { id } = useParams(); // :slug-:id parametresi

  const newid = id.split('-').pop(); // Slug ve ID'yi ayırıp ID'yi alıyoruz
  const [records, setRecords] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAllRecords = async () => {
      try {
        const res = await axios.get(`https://medrais.com/api/announcements/single/${newid}`);
        setRecords(res.data);
        console.log(res.data)
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllRecords();
  }, [id, newid]);

  return (
    <section className="block-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            {/* block content */}
            <div className="block-content">
              {/* single-post box */}
              <div className="single-post-box">
                <div className="title-post">
                  <h1>
                    {records.duyuru_title}{" "}
                  </h1>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {records.duyuru_date}
                    </li>
                    <li>
                      <i className="fa fa-user" />
                      {records.name}
                    </li>
                    <li>
                      <i className="fa fa-eye" />
                      {records.viewers}
                    </li>
                  </ul>
                </div>
                <div className="post-gallery">
                  <img src={records.annimage !== null && records.annimage !== '' ? records.annimage : `../upload/news-posts/single1.jpg`} alt="" />
                </div>
                <div className="post-content">
                  <p>
                    {records.duyuru_content}
                  </p>
                  <p>
                    <a href={records.duyuru_link}>Click here to go to the main announcement page.</a>

                  </p>
                  <p>Tags: {records.tag}</p>
                </div>
                <div className="share-post-box">
                  <ul className="share-box">
                    <li>
                      <i className="fa fa-share-alt" />
                      <span>Share Post</span>
                    </li>
                    <li>
                      <a className="facebook" href="/">
                        <i className="fa fa-facebook" />
                        Share on Facebook
                      </a>
                    </li>
                    <li>
                      <a className="twitter" href="/">
                        <i className="fa fa-twitter" />
                        Share on Twitter
                      </a>
                    </li>
                    <li>
                      <a className="google" href="/">
                        <i className="fa fa-google-plus" />
                        <span />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin" href="/">
                        <i className="fa fa-linkedin" />
                        <span />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* carousel box */}
                <div className="prev-next-posts">
                  <h3>You may also like</h3>
                  <div className="prev-post">
                    <img src="../upload/news-posts/listw4.jpg" alt="" />
                    <div className="post-content">
                      <h2>
                        <a href="single-post.html" title="prev post">
                          Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam.{" "}
                        </a>
                      </h2>
                      <ul className="post-tags">
                        <li>
                          <i className="fa fa-clock-o" />
                          27 may 2013
                        </li>
                        <li>
                          <a href="/">
                            <i className="fa fa-comments-o" />
                            <span>11</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="next-post">
                    <img src="../upload/news-posts/listw5.jpg" alt="" />
                    <div className="post-content">
                      <h2>
                        <a href="single-post.html" title="next post">
                          Donec consectetuer ligula vulputate sem tristique cursus.{" "}
                        </a>
                      </h2>
                      <ul className="post-tags">
                        <li>
                          <i className="fa fa-clock-o" />
                          27 may 2013
                        </li>
                        <li>
                          <a href="/">
                            <i className="fa fa-comments-o" />
                            <span>8</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


                {/* End carousel box */}
                {/* contact form box */}
                {/* End contact form box */}
              </div>
              {/* End single-post box */}
            </div>
            {/* End block content */}
          </div>
          <Sidebar />
        </div>
      </div>
    </section>


  )
}

export default SingleAnnouncement