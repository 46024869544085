import React from 'react'
import { useEffect, useState } from "react";
import axios from 'axios';

const Sidebar = () => {

  const [announcements, setAnnouncements] = useState([]);
  const getSlugFromUrl = (url) => {
    if (!url) return "";

    return url
        .split("/")
        .filter(Boolean)
        .pop()
        .replace("?", "");
};

  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const response = await axios.get("https://medrais.com/api/announcements/topViewers");
        setAnnouncements(response.data.announcements);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAdsData();
  }, []);
  return (
    <div className="col-sm-4">
    {/* sidebar */}
    <div className="sidebar">
      <div className="widget tab-posts-widget">
        <ul className="nav nav-tabs" id="myTab">
          <li className="active">
            <a href="#option1" data-toggle="tab">
              Popular
            </a>
          </li>
          {/* <li>
            <a href="#option2" data-toggle="tab">
              Recent
            </a>
          </li>
          <li>
            <a href="#option3" data-toggle="tab">
              Top Reviews
            </a>
          </li> */}
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="option1">
            <ul className="list-posts">
              <li>
                <img src={announcements[0]?.image} alt="" />
                <div className="post-content">
                  <h2>
                    <a  href={announcements[0]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[0]?.duyuru_link)}-${announcements[0]?.id}` : announcements[0]?.duyuru_link}>
                         {announcements[0]?.duyuru_title}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {announcements[0]?.duyuru_date}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src={announcements[1]?.image} alt="" />
                <div className="post-content">
                  <h2>
                    <a  href={announcements[1]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[1]?.duyuru_link)}-${announcements[1]?.id}` : announcements[1]?.duyuru_link}>
                         {announcements[1]?.duyuru_title}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {announcements[1]?.duyuru_date}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src={announcements[2]?.image} alt="" />
                <div className="post-content">
                  <h2>
                    <a  href={announcements[2]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[2]?.duyuru_link)}-${announcements[2]?.id}` : announcements[2]?.duyuru_link}>
                         {announcements[2]?.duyuru_title}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {announcements[2]?.duyuru_date}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src={announcements[3]?.image} alt="" />
                <div className="post-content">
                  <h2>
                    <a  href={announcements[3]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[3]?.duyuru_link)}-${announcements[3]?.id}` : announcements[3]?.duyuru_link}>
                         {announcements[3]?.duyuru_title}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {announcements[3]?.duyuru_date}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src={announcements[4]?.image} alt="" />
                <div className="post-content">
                  <h2>
                    <a  href={announcements[4]?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcements[4]?.duyuru_link)}-${announcements[5]?.id}` : announcements[4]?.duyuru_link}>
                         {announcements[4]?.duyuru_title}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      {announcements[4]?.duyuru_date}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="tab-pane" id="option2">
            <ul className="list-posts">
              <li>
                <img src="upload/news-posts/listw3.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Phasellus ultrices nulla quis nibh. Quisque a lectus.{" "}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw4.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Donec consectetuer ligula vulputate sem tristique
                      cursus.{" "}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw5.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Nam nulla quam, gravida non, commodo a, sodales sit
                      amet, nisi.
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw1.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Pellentesque odio nisi, euismod in, pharetra a,
                      ultricies in, diam.{" "}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw2.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">Sed arcu. Cras consequat.</a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="tab-pane" id="option3">
            <ul className="list-posts">
              <li>
                <img src="upload/news-posts/listw4.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Donec consectetuer ligula vulputate sem tristique
                      cursus.{" "}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw1.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Pellentesque odio nisi, euismod in, pharetra a,
                      ultricies in, diam.{" "}
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw3.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Phasellus ultrices nulla quis nibh. Quisque a lectus.
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw2.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">Sed arcu. Cras consequat.</a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <img src="upload/news-posts/listw5.jpg" alt="" />
                <div className="post-content">
                  <h2>
                    <a href="single-post.html">
                      Nam nulla quam, gravida non, commodo a, sodales sit
                      amet, nisi.
                    </a>
                  </h2>
                  <ul className="post-tags">
                    <li>
                      <i className="fa fa-clock-o" />
                      27 may 2013
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="advertisement">
        <div className="desktop-advert">
          <span>Advertisement</span>
          <img style={{boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",}} src="https://www.duyurular.org/duyurular_images/biyomed.jpg" alt="" />
        </div>
        <div className="tablet-advert">
          <span>Advertisement</span>
          <img src="upload/addsense/200x200.jpg" alt="" />
        </div>
        <div className="mobile-advert">
          <span>Advertisement</span>
          <img src="upload/addsense/300x250.jpg" alt="" />
        </div>
      </div>
    </div>
    {/* End sidebar */}
  </div>
  )
}

export default Sidebar