import React from 'react'

const NavbarCard = ({ announcement }) => {
  const getSlugFromUrl = (url) => {
    if (!url) return "";

    return url
        .split("/")
        .filter(Boolean)
        .pop()
        .replace("?", "");
};
  return (
    <div className="item news-post standard-post">
      <div className="post-gallery">
        <img style={{maxHeight:200, maxWidth:200}} src={announcement.image} alt="" />
      </div>
      <div className="post-content">
        <h2>
          <a href={announcement?.duyuru_content !== "" ? `/announcement/${getSlugFromUrl(announcement?.duyuru_link)}-${announcement?.id}` : announcement?.duyuru_link}>
            {announcement.duyuru_title.slice(0, 50) + '...'}
          </a>
        </h2>
        <ul className="post-tags">
          <li>
            <i className="fa fa-clock-o" />
            {announcement.duyuru_date}
          </li>
          <li>
            <i className="fa fa-map-marker" />
            <a href="/">{announcement.abbreviation ? announcement?.country + "-" + announcement?.abbreviation : announcement?.name}</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NavbarCard