import { useState, useEffect } from 'react';

const useFormattedDate = (dateString) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const formatDate = (dateString) => {
      const [day, month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-${day}`);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    if (dateString) {
      setFormattedDate(formatDate(dateString));
    }
  }, [dateString]);  // `dateString` değiştiğinde yalnızca çalışır

  return formattedDate;
};

export default useFormattedDate;
