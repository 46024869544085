import React from "react";
import MainDuyuru from "../Components/Body/MainDuyuru";

function HomePage() {
  return (
    <>
      <MainDuyuru />
    </>
  );
}

export default HomePage;
